import { formatLicenseExpiration } from '@/utils/durationUtils'
import { translate } from '@/utils/translations'

class LicenseExpiration {
  constructor({
    duration, durationInDays, daysLeftInLicense, isOnlineResource
  })
  {
    this.text = `${translate('common.expires')} ${formatLicenseExpiration(
      durationInDays, daysLeftInLicense
    )}`
    this.isPerpetual = duration === 'perpetual' && !isOnlineResource
  }
}

export default LicenseExpiration
