import { useSelector } from 'react-redux'

import ReadNowButton from '@/components/PDP/ReadNowButton'
import ReduxProvider from '@/components/ReduxProvider'
require('./index.scss')

import ActionButton from './ActionButton'
import Loader from './Loader'

const className = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal'
].join(' ')

const VariantActions = () => {
  const isFree = useSelector(state => state.asset.isFree)
  const isShowing = isFree !== undefined

  return (
    <Choose>
      <When condition={isShowing}>
        <Choose>
          <When condition={isFree}>
            <div className='u-flush--sides'>
              <ReadNowButton className={className} />
            </div>
          </When>
          <Otherwise>
            <ActionButton />
          </Otherwise>
        </Choose>
      </When>
      <Otherwise>
        <Loader />
      </Otherwise>
    </Choose>
  )
}

/*eslint-disable react/no-multi-comp*/
const Component = () => (
  <ReduxProvider>
    <VariantActions />
  </ReduxProvider>
)

export default Component
