import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { actions } from '@/reducers/asset'

import CheckMarkIcon from './checkmark_icon.svg'

const Radio = ({ variant: { fullVariant, id, isSelected } }) => {
  const dispatch = useDispatch()

  const changeSelectedVariant = () => {
    if (!isSelected) {
      dispatch(actions.updateSelectedVariant(fullVariant))
    }
  }

  return (
    <>
      <input
        className={isSelected ? 'isSelected' : undefined}
        defaultChecked={isSelected}
        id={id}
        name='variant'
        onChange={changeSelectedVariant}
        type='radio'
        value={id}
      />
      <If condition={isSelected}>
        <img alt='' className='checkmark-icon' src={CheckMarkIcon} />
      </If>
    </>
  )
}

export default Radio

Radio.propTypes = {
  variant: PropTypes.shape({
    fullVariant: PropTypes.shape({
      bookshelfAccessDuration: PropTypes.string.isRequired,
      duration: PropTypes.string.isRequired,
      durationInDays: PropTypes.string.isRequired,
      fullPrice: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      isFree: PropTypes.bool.isRequired,
      savings: PropTypes.number.isRequired,
      sku: PropTypes.string.isRequired,
      studentPrice: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired
    }),
    id: PropTypes.number.isRequired,
    isSelected: PropTypes.bool.isRequired
  })
}
