import { VisuallyHidden } from '@reach/visually-hidden'
import PropTypes from 'prop-types'
import Svg from 'react-inlinesvg'

const HEADING_STYLES = [
  'product-search-result__title',
  'styled_as_body_font_family',
  'u-weight--bold',
  'styled_as_h3',
  'mb-2'
].join(' ')

const SHARED_STYLES = [
  'styled_as_body_font__responsive',
  'u-weight--normal',
  'mb-2'
].join(' ')

const AssetDetails = ({
  assetProps,
  coverImageUrl,
  hasPriceRange,
  onClickHandler,
  productId,
  productUrl,
  formattedPrice,
  taxInfoMessage,
  translations
}) => {
  const {
    assetFormat,
    author,
    canonicalIsbn,
    edition,
    hasCoachMe,
    imprintName,
    isShowingFormatOnDetails,
    kind,
    packageCount,
    subtitle,
    title
  } = assetProps

  return (
    <>
      <a
        aria-hidden='true'
        href={`#${productId}`}
        tabIndex='-1'
      >
        <VisuallyHidden>
          {''}
        </VisuallyHidden>
      </a>
      <div className='d-flex flex-md-row flex-column mb-4 u-flush--bottom'>
        <div className='d-flex flex-fill flex-row'>
          <div className='search_result___image_wrapper'>
            <a
              aria-hidden='true'
              aria-labelledby={
                `cover_details_${canonicalIsbn} ${canonicalIsbn}`
              }
              href={productUrl}
              id={`cover_details_${canonicalIsbn}`}
              onClick={onClickHandler}
              tabIndex='-1'
            >
              <img
                alt=''
                className='product-cover__srp_image'
                src={coverImageUrl}
              />
            </a>
          </div>
          <div className='asset-srp-metadata col u-align--left p-0'>
            <div className='pill-outline-secondary-branded mb-3'>{kind}</div>
            <a
              aria-hidden='true'
              href={productUrl}
              onClick={onClickHandler}
              tabIndex='-1'
            >
              <h2 className={HEADING_STYLES} id={canonicalIsbn} title={title}>
                {title}
              </h2>
            </a>
            <div className={SHARED_STYLES}>
              {subtitle}
            </div>
            <ul className='horizontal-dictionary'>
              <If condition={edition}>
                <li className={SHARED_STYLES}>
                  {edition}
                </li>
              </If>
              <If condition={author}>
                <li className='font-5'>{author}</li>
              </If>
              <If condition={imprintName}>
                <li className='font-5'>{imprintName}</li>
              </If>
              <If condition={packageCount}>
                <li className='font-5'>{packageCount}</li>
              </If>
              <If condition={isShowingFormatOnDetails}>
                <li className='font-5'>{assetFormat}</li>
              </If>
              <If condition={hasCoachMe}>
                <li>
                  <div className='pill-square-secondary-branded coach-me'>
                    <Svg src={require('./images/coach-me.svg')} />
                    {translations.common_coachme_included}
                  </div>
                </li>
              </If>
            </ul>
          </div>
        </div>
        <div className='col-md-4 mt-4 mt-md-0 p-0 u-align--center'>
          <div>
            <p>
              <If condition={hasPriceRange}>
                <span className='font-5'>
                  {translations.common_product_price_from}
                </span>
              </If>
              <span className='block font-3 u-weight--bold'>
                {formattedPrice}
              </span>
              <If condition={taxInfoMessage}>
                <span>{taxInfoMessage}</span>
              </If>
            </p>
          </div>
          <a
            aria-labelledby={`view_details_${canonicalIsbn} ${canonicalIsbn}`}
            className='view vst-btn--pill vst-btn--branded-outline vs-btn'
            href={productUrl}
            id={`view_details_${canonicalIsbn}`}
            onClick={onClickHandler}
          >{translations.common_view_details}</a>
        </div>
      </div>
    </>
  )
}

export default AssetDetails

AssetDetails.propTypes = {
  assetProps: PropTypes.shape({
    assetFormat: PropTypes.string.isRequired,
    author: PropTypes.string,
    canonicalIsbn: PropTypes.string.isRequired,
    edition: PropTypes.string,
    hasCoachMe: PropTypes.bool.isRequired,
    imprintName: PropTypes.string,
    isPackage: PropTypes.bool,
    isShowingFormatOnDetails: PropTypes.bool.isRequired,
    kind: PropTypes.string.isRequired,
    packageCount: PropTypes.number,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    truncatedTitle: PropTypes.string.isRequired
  }),
  coverImageUrl: PropTypes.string.isRequired,
  formattedPrice: PropTypes.string.isRequired,
  hasPriceRange: PropTypes.bool.isRequired,
  onClickHandler: PropTypes.func,
  productId: PropTypes.string.isRequired,
  productUrl: PropTypes.string.isRequired,
  taxInfoMessage: PropTypes.string,
  translations: PropTypes.shape({
    common_coachme_included: PropTypes.string.isRequired,
    common_product_price_from: PropTypes.string.isRequired,
    common_view_details: PropTypes.string.isRequired
  })
}
