import PropTypes from 'prop-types'

import ReadNowLink from '@/components/ReadNowLink'

import CoursewareButton from './CoursewareButton'

const classNameForReadNow = [
  'vst-btn--action',
  'vst-btn--orange',
  'vst-btn--read-now',
  'u-weight--normal',
  'asset-purchased-read-now'
].join(' ')

const className = [
  'u-flush--sides',
  'u-align--center',
  'type--ninja10'
].join(' ')

const ReadNow = ({
  isAcrobatiq,
  isBundle,
  isGuestReferenceUser,
  isOnlineResource,
  isShowingCustomerSupportUrl,
  isShowingExpirationStatus,
  readNowExpirationDays,
  readNowUrl,
  supportUrl,
  translations
 }) => {

  const {
    common_access_code_send_by_email,
    common_customer_support,
    common_read_in_bookshelf
  } = translations

  return (
    <If condition={!isGuestReferenceUser}>
      <div>
        <If condition={isShowingExpirationStatus}>
          <div className={className}>
            <p>{readNowExpirationDays}</p>
          </div>
        </If>
        <div className='u-flush--sides'>
          <Choose>
            <When condition={isOnlineResource}>
              <CoursewareButton {...{
                isShowingCustomerSupportUrl,
                supportUrl,
                translations: {
                  common_access_code_send_by_email,
                  common_customer_support
                }
              }}
              />
            </When>
            <When condition={isBundle}>
              <If condition={!isAcrobatiq}>
                <ReadNowLink
                  className={classNameForReadNow}
                  page={'some-page-name'}
                  route={readNowUrl}
                >
                  {common_read_in_bookshelf}
                </ReadNowLink>
              </If>

              <CoursewareButton {...{
                isShowingCustomerSupportUrl,
                supportUrl,
                translations: {
                  common_access_code_send_by_email,
                  common_customer_support
                }
              }}
              />
            </When>
            <Otherwise>
              <ReadNowLink
                className={classNameForReadNow}
                page={'some-page-name'}
                route={readNowUrl}
              >
                {common_read_in_bookshelf}
              </ReadNowLink>
            </Otherwise>
          </Choose>
        </div>
      </div>
    </If>
  )
}

export default ReadNow

ReadNow.propTypes = {
  isAcrobatiq: PropTypes.bool.isRequired,
  isBundle: PropTypes.bool.isRequired,
  isGuestReferenceUser: PropTypes.bool.isRequired,
  isOnlineResource: PropTypes.bool.isRequired,
  isShowingCustomerSupportUrl: PropTypes.bool.isRequired,
  isShowingExpirationStatus: PropTypes.bool.isRequired,
  readNowExpirationDays: PropTypes.string.isRequired,
  readNowUrl: PropTypes.string.isRequired,
  supportUrl: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    common_access_code_send_by_email: PropTypes.string.isRequired,
    common_customer_support: PropTypes.string.isRequired,
    common_read_in_bookshelf: PropTypes.string.isRequired
  })
}
