import { formatMoney } from 'accounting-js'

import store from '@/store'

import { translate } from './translations'

/**
 * @deprecated Please use the price factory with the LOCALE_PRICE_TYPE
 * for a stateless implementation of this function.
 */

/* eslint-disable complexity */
export const localePrice = (price, options) => {
  const { hideCurrencyLabel, hideCurrencySymbol } = (options || {})
  const { useNZTax } = (options || {})

  let {
    currencyLabel,
    currencySymbol,
    isZeroDecimalCurrency,
    taxMultiplier,
  } = store.getState().currentRegion

  let { flatTaxMultiplier } = store.getState().taxes

  if (!currencyLabel || hideCurrencyLabel) {
    currencyLabel = ''
  } else {
    currencyLabel = ' ' + currencyLabel
  }

  if (!currencySymbol || hideCurrencySymbol) {
    currencySymbol = ''
  }

  if (useNZTax) {
    const multiplier = 1 + flatTaxMultiplier

    taxMultiplier = multiplier
  }

  taxMultiplier = parseFloat(taxMultiplier)
  const totalPrice = price * taxMultiplier
  const currencyPrecision = isZeroDecimalCurrency ? 0 : 2

  if (totalPrice > 0) {
    const formattedPrice = formatMoney(
      totalPrice, { precision: currencyPrecision, symbol: currencySymbol }
    )

    price = formattedPrice + currencyLabel

    return price
  } else {
    return translate('common.no_price_product')
  }
}
