import { useSelector } from 'react-redux'

import AddToCartButton from '@/components/PDP/PricingPanel/AddToCartButton'
import { isShowingQuantityInputOnPDPSelector } from '@/selectors'

import QuantityInput from './QuantityInput'

const BulkStoreAddToCartButton = () => {
  const isShowing = useSelector(isShowingQuantityInputOnPDPSelector)

  return (
    <div className='asset-action-bulk-enabled'>
      <If condition={isShowing}>
        <QuantityInput />
      </If>
      <AddToCartButton />
    </div>
  )
}

export default BulkStoreAddToCartButton
