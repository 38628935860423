import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ExternalLinkIcon from '@/components/ExternalLinkIcon'
import { translate } from '@/utils/translations'

const buttonStyles = [
  'my-4',
  'vs-btn',
  'type--ocean6',
  'vst-btn--ocean-outline',
  'vst-btn--pill'
].join(' ')

const WebsiteButtonOutline = ({ isbn }) => {
  const loginUrl = useSelector(
    state => state.onlineResourceModal[isbn].loginUrl
  )

  return (
    <a
      className={buttonStyles}
      href={loginUrl}
      rel='noreferrer noopener'
      target='_blank'
    >
      {translate('order_history.button.go_to_website')}
      <ExternalLinkIcon theme='light' />
    </a>
  )
}

WebsiteButtonOutline.propTypes = { isbn: PropTypes.string.isRequired }

export default WebsiteButtonOutline
