// Entries here are only required if the component is not located at
// @/components/<name>. If it is in a subfolder, an entry should be added
// to map the component name to its location under the @/components folder
export default {
  ETextbookFeatures: 'PDP/ETextbookFeaturesSection',
  OnlineResourceAccess: 'PDP/TabContainers/OnlineResourceAccess',
  PDPHowToVideoSection: 'PDP/HowToVideoSection',
  PDPShareTools: 'PDP/PDPShareTools',
  Packages: 'PDP/Packages',
  ProductDetails: 'PDP/TabContainers/ProductDetails',
  ProductImageZoom: 'PDP/ProductImageZoom',
  ReadNowBanner: 'PDP/ReadNowBanner',
  ReadNowButton: 'PDP/ReadNowButton'
}
