import Spinner from '@/components/Spinner'

const className = [
  'vst-btn--action',
  'vst-btn--orange',
  'add_to_cart',
  'u-soft-half',
  'u-weight--normal'
].join(' ')

const Loader = () => <div className={className}><Spinner /></div>

export default Loader
