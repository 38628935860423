import { useDispatch, useSelector } from 'react-redux'

import { actions as assetActions } from '@/reducers/asset'
import { translate } from '@/utils/translations'

const QuantityInput = () => {

  const isBadNumber = number => (
    isNaN(number)
    || Math.sign(number) === -1
    || Math.floor(number) === 0
  )

  const sanitizedValue = number => isBadNumber(number) ? '' : Math.floor(number)

  const forceNumber = value => sanitizedValue(value)

  const quantity = useSelector(state => state.asset.quantity)
  const dispatch = useDispatch()

  const readCount = e => {
    const number = forceNumber(e.target.value)
    dispatch(assetActions.updateQuantity(number))
  }

  return (
    <div className='pdp-quantity-input'>
      <label htmlFor='pdp-quantity'>
        {translate('common.quantity_abbreviation')}
      </label>
      <input
        id='pdp-quantity'
        name='pdp-quantity'
        onChange={readCount}
        type='text'
        value={quantity}
      />
    </div>
  )
}

export default QuantityInput
