import { localePrice } from '@/utils/priceLocalization'

const StudentPrice = function ({ studentPrice }) {
  this.isShowing = Boolean(studentPrice)
  this.formattedAmount = localePrice(
    studentPrice, { hideCurrencyLabel: true }
  )
}

export default StudentPrice
