import Total from '@/components/Total'
import variantSelectorContainerShape
  from '@/propTypeShapes/variantSelectorContainer'

import CarbonNeutralityDialog from '../CarbonNeutralityDialog'
import BulkStorePurchaseOptionsLink from './BulkStorePurchaseOptionsLink'
import Expiration from './Expiration'
import GiftModal from './GiftModal'
import PayPalBNPL from './PayPalBNPL'
import ReadNowBanner from './ReadNowBanner'
import Savings from './Savings'
import Title from './Title'
import VariantActions from './VariantActions'
import Variants from './Variants'

const VariantSelectorContainer = ({
  bulkStorePurchaseOptionsLinkProps,
  canShowGiftLink,
  canShowCarbonNeutrality,
  currency,
  isPayPalBNPLEnabled,
  isShowingExpiration,
  isShowingSavings,
  isZeroDecimalCurrency,
  readNowBannerProps,
  readNowButtonProps,
  taxes,
  titleProps,
  total,
  translations,
  variantsProps
}) => {

  return (
    <div>
      <div className='variant-selector-container selector card'>
        <div className='variant-selector-wrap'>
          <ReadNowBanner
            readNowBannerProps={readNowBannerProps}
            readNowButtonProps={readNowButtonProps}
          />
          <fieldset>
            <Title {...{ ...titleProps }} />
            <div className='variants-wrapper'>
              <Variants {...{
                currency,
                isZeroDecimalCurrency,
                taxes,
                translations,
                variantsProps,
              }}
              />
            </div>
          </fieldset>
        </div>
        <div className='expiration-and-total-wrap'>
          <Expiration isShowingExpiration={isShowingExpiration} />
          <Savings {...{ isShowingSavings }} />

          <Total {...{
            currency,
            isZeroDecimalCurrency,
            taxes,
            total
          }}
          />
          <VariantActions />
          <PayPalBNPL {...{ isPayPalBNPLEnabled, total }} />
          <BulkStorePurchaseOptionsLink
            {...{ ...bulkStorePurchaseOptionsLinkProps }}
          />
        </div>
      </div>
      <If condition={canShowGiftLink}>
        <GiftModal />
      </If>

      <If condition={canShowCarbonNeutrality}>
        <CarbonNeutralityDialog />
      </If>
    </div>
  )
}

export default VariantSelectorContainer

VariantSelectorContainer.propTypes = variantSelectorContainerShape
