import PropTypes from 'prop-types'

import { translate } from '@/utils/translations'

const classNames = [
  'vst-btn--orange-outline',
  'vst-btn--action',
  'vst-btn--large-full-width'
].join(' ')

const CloseButton = ({ onClick }) => (
  <div className='row'>
    <button
      aria-label={translate('common.close')}
      className={classNames}
      onClick={onClick}
      type='button'
    >
      {translate('common.cancel')}
    </button>
  </div>
)

export default CloseButton

CloseButton.propTypes = { onClick: PropTypes.func.isRequired }
