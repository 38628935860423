import PropTypes from 'prop-types'

const CartItemExpiration = ({ cartItemVariant }) => {
  const { isShowingExpiration, isPerpetual } = cartItemVariant

  return (
    <If condition={isShowingExpiration && !isPerpetual}>
      <span className='duration-details-text'>
        {cartItemVariant.expiration}
      </span>
    </If>
  )
}

export default CartItemExpiration

CartItemExpiration.propTypes = {
  cartItemVariant: PropTypes.shape({
    duration: PropTypes.string.isRequired,
    expiration: PropTypes.string.isRequired,
    isPerpetual: PropTypes.bool.isRequired,
    isShowingExpiration: PropTypes.bool.isRequired
  })
}
