import dayjs from 'dayjs'

import { translate } from '@/utils/translations'

const PERPETUAL = 'perpetual'

export const formatLicenseExpiration = (
  numberOfDaysAsString,
  daysLeftInLicense
) => {
  const duration = parseInt(numberOfDaysAsString, 10) + daysLeftInLicense

  return formatExpirationDate(duration)
}

export const formatExpirationDate = numberOfDaysAsString => {
  const expirationDate = dayjs().add(parseInt(numberOfDaysAsString, 10), 'day')

  return expirationDate.format('MMM D, YYYY')
}

export const durationText = duration => duration === PERPETUAL
  ? translate('purchase.buy_eTextbook')
  : duration
